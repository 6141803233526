import CareEntryListRow, {
  CareEntryRowVariant,
  ExtendedCareEntrySearchResult,
} from "@components/CareEntryRow";
import {useListBoundaryController} from "@components/Locations/useClinicListBoundaryController";
import React, {useCallback, useRef} from "react";

import {useExtendCareEntries} from "../../../../hooks/useCareEntryExtensions";
import {useHoveredIndexController} from "../../../../hooks/useHoveredIndexController";
import useMediaQuery from "../../../../useMediaQuery";
import Collapse from "../../../animations/Collapse";
import {PhoneNumber} from "../../CareDiscovery/PhoneNumber";
import {CareDiscoveryRowClickReporter} from "./careDiscoveryAnalytics";
import {useTopicStack} from "./useTopicStack";
import {SelectedLocation} from "@components/CareEntryRow/Control";

interface Props {
  keyPrefix: string;
  careEntries: ExtendedCareEntrySearchResult[];
  transitionSpeed: number;
  selectedLocation?: SelectedLocation;
  highlightQuery?: string;
  rowVariant?: CareEntryRowVariant;
  hide?: boolean;
  hideListController?: boolean;
  hideBorders?: boolean;
  shouldExpandIfTopic?: boolean;
  className?: string;
  loadingRowCount?: number;
  disableAnimation?: boolean;
  phoneNumber?: string | null;
  reportClickEvent: CareDiscoveryRowClickReporter;
  maybeToggleDialog: (shouldRedirect: boolean) => void;
  maybeAddCareToTopicStack: ReturnType<typeof useTopicStack>["maybeAddToStack"];
  canSendApptStartEvent?: boolean;
  viewId?: string;
}

const border = "after:content-[''] after:h-[1px] after:w-[100%] after:flex after:transition-colors";

const borderStyleSwitch = (disabled: boolean) =>
  disabled ? `${border} after:bg-transparent` : `${border} after:bg-gray100`;

const getBorder = (
  index: number,
  hoveredIndex: number | null,
  totalItemCount: number,
  disable: boolean,
) =>
  borderStyleSwitch(
    disable ||
      index === hoveredIndex ||
      index === (hoveredIndex || -100) - 1 ||
      index === totalItemCount - 1,
  );

const getRowKey = (prefix: string, slug: string) => `${prefix}-${slug}`;
const blankResult = {name: "", slug: ""};

const ResultList: React.FC<Props> = ({
  viewId,
  keyPrefix,
  careEntries,
  transitionSpeed,
  selectedLocation,
  highlightQuery,
  rowVariant,
  hide,
  hideBorders = false,
  hideListController = false,
  className = "",
  loadingRowCount = 0,
  shouldExpandIfTopic = false,
  disableAnimation = false,
  phoneNumber,
  reportClickEvent,
  maybeToggleDialog,
  maybeAddCareToTopicStack,
  canSendApptStartEvent = false,
}) => {
  const isSm = useMediaQuery("sm");
  const shouldBindList = isSm && !hideListController;
  const firstLocRef = useRef<HTMLLIElement | null>(null);

  const extendedCareEntries = useExtendCareEntries(careEntries);
  const {ListController, bindList} = useListBoundaryController(extendedCareEntries.length, 4);
  const boundList = shouldBindList ? bindList(extendedCareEntries) : extendedCareEntries;
  const showListController = shouldBindList && extendedCareEntries.length > 6;
  const onListReset = useCallback(() => {
    if (firstLocRef.current) {
      firstLocRef.current.scrollIntoView({block: "center", behavior: "smooth"});
    }
  }, [firstLocRef]);

  const {activeIndex, setHovered, unsetHovered} = useHoveredIndexController();

  return loadingRowCount ? (
    <div className="flex flex-col transition">
      {Array(loadingRowCount)
        .fill(null)
        .map((_, i, arr) => (
          // Normally do not ignore these, but this is only to set a hover style and has no a11y implication
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            key={getRowKey(keyPrefix, i.toString())}
            className={`list-none ${getBorder(i, activeIndex, arr.length, hideBorders)}`}
            onMouseEnter={() => setHovered(i)}
          >
            <CareEntryListRow
              rank={i + 1}
              cypressKey={`${keyPrefix}-${i}-loading`}
              result={blankResult}
              variant={rowVariant}
              isLoading
            />
          </li>
        ))}
    </div>
  ) : (
    // Normally do not ignore these, but this is only to set a hover style and has no a11y implication
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ul className={`flex flex-col ${className}`} onMouseLeave={unsetHovered}>
      {boundList.map((result, i, arr) => (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li
          key={`${keyPrefix}-${i}-${result.name}`}
          ref={i === 0 ? firstLocRef : undefined}
          onMouseEnter={() => setHovered(i)}
          className={`list-none ${getBorder(
            i,
            activeIndex,
            arr.length + (phoneNumber ? 1 : 0),
            hideBorders,
          )}`}
        >
          <Collapse
            in={!hide}
            appear
            disableAnimation={disableAnimation}
            duration={transitionSpeed}
            unmountOnExit
          >
            <CareEntryListRow
              key={`${keyPrefix}-${i}-${result.slug}`}
              cypressKey={`${keyPrefix}-${result.slug}`}
              rank={i + 1}
              result={result}
              selectedLocation={selectedLocation}
              query={highlightQuery}
              variant={rowVariant}
              shouldExpandIfTopic={shouldExpandIfTopic}
              isLoading={false}
              canSendApptStartEvent={canSendApptStartEvent}
              reportClickEvent={reportClickEvent}
              maybeToggleDialog={maybeToggleDialog}
              maybeAddCareToTopicStack={maybeAddCareToTopicStack}
              viewId={viewId}
            />
          </Collapse>
        </li>
      ))}
      {phoneNumber && <PhoneNumber phoneNumber={phoneNumber} />}
      {showListController && <ListController onReset={onListReset} />}
    </ul>
  );
};

export default React.memo(ResultList);
